@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.btn_primary,
.ant-btn-primary {
	background-color: #008cff;
	color: #fff !important;
	border-radius: 6px;
	border: none;
	padding: 10px;
	font-size: 14px;
	cursor: pointer;
	transition: all 0.3s;
	gap: 5px;
}
.btn_primary:hover,
.ant-btn-primary:hover {
	background-color: #42a9fd;
	color: #fff;
}

.btn_primary {
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn_secondary {
	background-color: transparent;
	color: #008cff;
	border-radius: 6px;
	border: 1px solid #008cff;
	padding: 10px;
	font-size: 14px;
	cursor: pointer;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
}
.btn_secondary:hover {
	background-color: #008cff;
	color: #fff;
}

.btn_ternary {
	background-color: white !important;
	color: #000 !important;
	border-radius: 6px;
	border: 1px solid #d9d9d9;
	padding: 10px;
	font-size: 14px;
	cursor: pointer;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
}
.btn_ternary:hover {
	border: 1px solid #008cff;
	color: #008cff !important;
}

.btn_error {
	background-color: transparent;
	color: #ff0000 !important;
	border-radius: 6px;
	border: 1px dashed #ff0000 !important;
	padding: 10px;
	font-size: 14px;
	cursor: pointer;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
}
.btn_error:hover {
	opacity: 0.6;
}

.mdf_container {
	height: calc(100vh - 120px);
	display: flex;
	gap: 16px;
	padding: 10px 0;
}

.mdf_content {
	height: 100%;
	flex: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 16px;
}
