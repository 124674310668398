.box_view_template_page {
	height: calc(100vh - 60px);
	overflow-y: auto;
	overflow-x: hidden;
	background-color: white;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		display: block;
		font-weight: bold;
		letter-spacing: -0.02em;
	}

	h1 {
		font-size: 2rem;
		line-height: 2.5rem;
	}

	h2 {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	h3 {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	h4 {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	h5 {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	h6 {
		font-size: 0.75rem;
		line-height: 1rem;
	}

	p {
		font-weight: 400;
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: -0.02em;
	}

	a {
		font-weight: 400;
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: -0.02em;
	}
}
.btn-grad {
	background-image: linear-gradient(
		to right,
		#000046 0%,
		#1cb5e0 51%,
		#000046 100%
	);
	margin: 10px;
	padding: 0px 20px;
	text-align: center;
	transition: 0.5s;
	background-size: 200% auto;
	color: white;
	box-shadow: 0 0 20px #eee;
	border-radius: 10px;
	display: block;
	&:hover {
		background-position: right center; /* change the direction of the change here */
		color: #fff !important;
		text-decoration: none;
	}
}
.btn-grad_2 {
	background-image: linear-gradient(
		to right,
		#f7971e 0%,
		#ffd200 51%,
		#f7971e 100%
	);
	margin: 10px;
	padding: 0px 20px;
	text-align: center;
	transition: 0.5s;
	background-size: 200% auto;
	color: #fff;
	border: none;
	border-radius: 10px;
	display: block;
	&:hover {
		background-position: right center; /* change the direction of the change here */
		color: #fff !important;
		text-decoration: none;
	}
}

// SectionBanner
.box_banner_1 {
	background-color: #ffffff;
	background-image: linear-gradient(
		160deg,
		#ffffff 0%,
		#80d0c7 33%,
		#0093e9 66%,
		#09395e 100%
	);
	padding: 90px 15px 176px;
	@media screen and (max-width: 990px) {
		flex-direction: column-reverse;
		.box_banner_1_left {
			width: 100%;
			.box_banner_1_left_content {
				margin-top: 3rem;
				text-align: center;
				button {
					margin: 0 auto;
				}
			}
		}
		.box_banner_1_right {
			width: calc(100% * 2 / 3);
		}
	}
	@media screen and (max-width: 520px) {
		padding: 40px 15px 40px;
		.box_banner_1_left .box_banner_1_left_content {
			width: 100%;
			h1 {
				font-size: 24px;
				line-height: 1.2;
			}
			p {
				font-size: 14px;
				line-height: 1.2;
			}
		}
	}
}

.box_banner {
	color: white;
	position: relative;
	::before {
		background-image: url(./assets/images/bg_banner.svg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		content: "";
		height: 150px;
		left: 0;
		position: absolute;
		right: 0;
		width: 100%;
		z-index: 1;
		top: -150px;
	}
	@media screen and (max-width: 520px) {
		position: absolute;
	}
	.box_banner_left {
		background-size: cover;
		background-repeat: no-repeat;
		.box_banner_left_content {
			background-color: #09395ee8;
			padding: 1.75rem 1.75rem 3rem 5rem;
		}
		@media (max-width: 1280px) {
			.box_banner_left_content {
				padding: 1.75rem 1.75rem 3rem 3rem;
			}
		}
	}
	.box_banner_right {
		background-size: cover;
		background-repeat: no-repeat;
		.box_banner_right_content {
			background-color: #573b031a;
		}
	}
	@media (max-width: 1200px) {
		.box_banner_left {
			width: 50%;
			.box_banner_left_content {
				padding: 1.75rem 1.75rem 3rem 5rem;
			}
		}
		.box_banner_right {
			width: 50%;
		}
	}
	@media screen and (max-width: 1050px) {
		.box_banner_left {
			width: 100%;
			.box_banner_left_content {
				height: 100%;
			}
		}
		.box_banner_right {
			width: 0%;
			display: none;
		}
	}
	@media screen and (max-width: 768px) {
		.box_banner_left {
			.box_banner_left_content {
				padding: 1.75rem 1.75rem 3rem 1.75rem;
			}
		}
	}
}
// SectionBanner

// SectionContent1
.section_service_1 {
	padding: 50px 0 25px;
	.box_service_1 {
		@media screen and (max-width: 520px) {
			padding: 0 1rem;
		}
	}
	@media screen and (max-width: 1050px) {
		.box_service_1_content {
			flex-wrap: wrap;
			.box_service_1_content_left {
				width: 60%;
			}
			.box_service_1_content_right {
				text-align: center;
				width: 100%;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.box_service_1_content .box_service_1_content_left {
			width: 80%;
		}
	}
}
// SectionContent1

// SectionContent2
.section_service_4 {
	padding: 25px 0;
	.box_service_4 {
		padding: 35px 0.4rem;
		background-color: #f8f8f8;
		@media screen and (max-width: 520px) {
			padding: 35px 1rem;
		}
	}
	@media screen and (max-width: 1050px) {
		.box_service_4_content {
			flex-wrap: wrap;
			justify-content: center;
			gap: 40px;
			.item_service_4_content {
				width: 60%;
			}
		}
	}
	@media screen and (max-width: 860px) {
		.box_service_4_content {
			.item_service_4_content {
				width: 70%;
			}
		}
	}
	@media screen and (max-width: 640px) {
		.box_service_4_content {
			.item_service_4_content {
				width: 90%;
			}
		}
	}
}
// SectionContent2

// SectionContent3
.box_software_sales_1 {
	@media screen and (max-width: 768px) {
		.box_header_software_sales_1 {
			width: 80%;
		}
	}
}
.box_content_software_sales_1_item {
	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
	}
}
.item_2_reverse {
	@media screen and (max-width: 768px) {
		flex-direction: column-reverse;
	}
}
.box_content_software_sales_1 {
	@media screen and (max-width: 1024px) {
		width: 80%;
	}
	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
		.item_left {
			width: calc(100% * 2 / 3);
		}
		.item_right {
			width: 80%;
			h1 {
				text-align: center;
			}
		}
	}
	@media screen and (max-width: 640px) {
		.item_left {
			width: 80%;
		}
		.item_right {
			width: 100%;
		}
	}
}
// SectionContent3

// SectionContent4
.box_software_sales_2 {
	background-image: url(./assets/images/bg_shape.jpg);
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: 100% 100%;
	background-size: cover;
	background-attachment: fixed;
	.box_content_software_sales_2_item {
		background-color: #ffffffe3;
	}
	@media screen and (max-width: 1024px) {
		.box_content_software_sales_2_item {
			.item_bottom {
				width: 80%;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.box_header_software_sales_2 {
			width: 80%;
		}
		.box_content_software_sales_2_item {
			width: calc(100% * 2 / 3);
		}
	}
	@media screen and (max-width: 640px) {
		.box_content_software_sales_2_item {
			width: 100%;
		}
	}
	@media screen and (max-width: 425px) {
		padding-left: 1.25rem /* 20px */;
		padding-right: 1.25rem /* 20px */;
		.box_content_software_sales_2 {
			padding-left: 0;
			padding-right: 0;
			.box_content_software_sales_2_item {
				padding-left: 0.5rem /* 8px */;
				padding-right: 0.5rem /* 8px */;
				.item_top {
					padding-left: 0.25rem /* 4px */;
					padding-right: 0.25rem /* 4px */;
				}
				.item_bottom {
					width: 100%;
				}
			}
		}
	}
}
// SectionContent4
