.box_signin {
	background-image: url("../../assets/images/web_designs.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	.button_signin {
		.ant-form-item-row {
			width: 100% !important;
		}
	}
}
