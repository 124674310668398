.components-table-demo-control-bar .ant-form-item {
	margin-right: 16px !important;
	margin-bottom: 8px !important;
}
.field_title {
	.show_group_button {
		height: 22px;
		span {
			display: none;
		}
	}
}

.ant-table-thead > tr > * {
	background-color: white !important;
}

.ant-table-row:hover .show_group_button span {
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 5px;
}

.ant-table-tbody > :nth-child(even) > td {
	background-color: #fafafa !important;
}
.ant-table-cell {
	border-bottom: none !important;
	cursor: pointer;
}
.ant-table-cell-row-hover {
	color: #25acff;
	background-color: #e7f1ff !important;
}
